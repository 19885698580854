import profilePictureSvg from '!!raw-loader!../../assets/profile-picture-home.svg';
import {css, Global, GlobalProps} from '@emotion/core';
import {faAngleDoubleDown} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Children, cloneElement, isValidElement, useEffect, useMemo, useState} from 'react';
import {MIN_DEFAULT_MEDIA_QUERY, MIN_LARGER_DISPLAY_MEDIA_QUERY, MIN_MOBILE_MEDIA_QUERY, MIN_TABLET_MEDIA_QUERY} from 'typography-breakpoint-constants';
import {animationDuration} from '../../styles/animations';
import {colors} from '../../styles/colors';
import {gradient45deg} from '../../styles/css';
import {textStroke} from '../../styles/textStroke';
import {font100, font150, font200, font250, font300, font400, font50, font500, rhythm2, rhythm4, rhythm5} from '../../styles/typography';
import {Container} from '../Container';

const globalStyles: GlobalProps<unknown>['styles'] = {
  '.header-logo-profile-picture': {
    display: 'none',
  },
  '.header-logo': {
    // WebkitTextStroke: '1px white',
    textShadow: textStroke(1, 'black'),
    color: 'white',

    [MIN_LARGER_DISPLAY_MEDIA_QUERY]: {
      textShadow: textStroke(1, 'white'),
      color: 'black',
    },

    // color: '#fa4647',

    // '& span': {
    //   display: 'inline-block',
    //   position: 'relative',

    //   backgroundImage: gradient,
    //   // backgroundClip: 'text',
    //   WebkitBackgroundClip: 'text',
    //   WebkitTextFillColor: 'transparent',

    //   '&::after': {
    //     background: 'none',
    //     content: 'attr(data-text)',
    //     zIndex: -1,
    //     left: 0,
    //     position: 'absolute',
    //     // text-shadow: 6px 6px 9px rgba(0, 0, 0, 0.33);
    //     top: 0,
    //     textShadow: textStroke(1, 'white'),
    //   },
    // },
  },
  [`
    .main-menu-hamburger,
    .main-menu-locale-link
  `]: {
    textShadow: textStroke(1, '#fff'),

    '&:hover, &:focus, &:active': {
      textShadow: 'none',
    },
  },
  body: {
    background: `white url('data:image/svg+xml;charset=utf-8,${encodeURIComponent(profilePictureSvg)}') no-repeat top left`,
    backgroundSize: ['auto 100vh', 'auto var(--window-height, 100vh)'],
  },
  '.layout-wrapper': {
    paddingTop: [`calc(100vh - ${rhythm2})`, `calc(var(--window-height, 100vh) - ${rhythm2})`],
  },
};

const homeHeroCss = css({
  position: 'absolute',
  left: 0,
  top: 0,
  height: ['100vh', 'var(--window-height)'],
  // minHeight: rhythm25,
  width: '100%',
  overflow: 'hidden',
  // background: `url('data:image/svg+xml;charset=utf-8,${encodeURIComponent(profilePictureSvg)}') no-repeat top left`,
  // backgroundSize: 'auto 100vh',
  // background: `url('data:image/svg+xml;charset=utf-8,${encodeURIComponent(profilePictureSvg)}') no-repeat left`,
  // backgroundSize: 'auto 100%',

  // '& + *': {
  //   // marginTop: 'var(--window-height, 100vh)',
  //   marginTop: [`calc(100vh - ${rhythm1})`, `calc(var(--window-height, 100vh) - ${rhythm1})`],
  // },
});

// const childrenCss = css({
//   position: 'absolute',
//   top: 0,
//   left: '100%',
//   width: '100vh',
//   height: '100vh',

//   transform: 'rotate(90deg)',
//   transformOrigin: 'top left',
//   // textTransform: 'uppercase',

//   [`
//     & h1,
//     & h2,
//     & h3,
//     & h4,
//     & h5,
//     & h6,
//     & p`
//   ]: {
//     marginBottom: 0,
//     marginRight: rhythm2,
//     textShadow: textStroke(2, 'white'),

//     [`
//       & a:hover,
//       & a:focus,
//       & a:active`
//     ]: {
//       textShadow: 'none',
//     },
//   },

//   '& a': {
//     fontWeight: 600,
//   },

//   '& p': {
//     fontFamily: fontFamilyArrayToString(headerFontFamily),
//   },

//   '& div': {
//     display: 'flex',
//     flexDirection: 'column',
//     alignItems: 'flex-end',
//   },

//   '& h1': {
//     ...typography.scale(1.8),
//   },

//   '@media (orientation: portrait) and (min-height: 630px)': {
//     '& h1': {
//       ...typography.scale(2.1),
//     },

//     '& p': {
//       ...typography.scale(0.45),
//     },
//   },

//   '@media (orientation: portrait) and (min-height: 710px)': {
//     '& h1': {
//       ...typography.scale(2.4),
//     },

//     '& p': {
//       ...typography.scale(0.6),
//     },
//   },

//   '@media (orientation: portrait) and (min-height: 900px)': {
//     '& h1': {
//       ...typography.scale(3.3),
//     },

//     '& p': {
//       ...typography.scale(1.5),
//     },
//   },

//   '@media (orientation: portrait) and (min-height: 1100px)': {
//     '& h1': {
//       ...typography.scale(4.2),
//     },

//     '& p': {
//       ...typography.scale(2.25),
//     },
//   },

//   '@media (orientation: landscape)': {
//     transform: 'none',
//     left: 0,
//     height: '100vh',
//     width: '100vw',

//     display: 'flex',
//     alignItems: 'center',
//     justifyContent: 'flex-start',

//     marginLeft: rhythm5,

//     '& h1': {
//       marginRight: 0,
//     },

//     '& p': {
//       marginRight: 0,
//     },

//     '& div': {
//       alignItems: 'flex-start',
//     },
//   },

//   '@media (orientation: landscape) and (min-width: 600px)': {
//     marginLeft: typography.rhythm(7),
//   },

//   '@media (orientation: landscape) and (min-width: 800px)': {
//     marginLeft: typography.rhythm(10),

//     '& h1': {
//       ...typography.scale(3.3),
//     },

//     '& p': {
//       ...typography.scale(1),
//     },
//   },

//   '@media (orientation: landscape) and (min-width: 1000px)': {
//     marginLeft: typography.rhythm(15),
//   },

//   '@media (orientation: landscape) and (min-width: 1200px)': {
//     marginLeft: typography.rhythm(16),
//   },

//   '@media (orientation: landscape) and (min-width: 1700px)': {
//     marginLeft: typography.rhythm(20),
//   },
// });

const childrenCss = css({
  position: 'absolute',
  // top: '40vh',
  left: 0,
  top: 0,
  height: '100%',
  width: '100%',
  textAlign: 'center',

  [MIN_TABLET_MEDIA_QUERY]: {
    textAlign: 'left',
  },
});

const childrenContainerCss = css({
  height: '100%',
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'flex-end',
  paddingBottom: ['56px', `calc(56px + ${rhythm5})`],

  '@media (orientation: landscape)': {
    justifyContent: 'flex-end',
    alignItems: 'center',
    paddingBottom: 0,
  },

  [MIN_TABLET_MEDIA_QUERY]: {
    justifyContent: 'flex-end',
    alignItems: 'center',
    paddingBottom: 0,
  },
});

const childrenWrapperCss = css({
  // display: 'flex',
  // justifyContent: 'flex-end',
  // padding: `0 ${typography.rhythm(0.5)}`,

  // -webkit-text-fill-color: transparent;

  // left: '100%',
  // width: '100vh',
  // width: '100vh',
  // display: 'flex',
  // justifyContent: 'center',
  color: 'white',
  textShadow: textStroke(1, '#000'),

  '& h1': {
    ...font250,

    '@media (min-width: 355px)': {
      ...font300,
    },

    lineHeight: 1.1,
    textShadow: 'none',

    color: '#fa4647',

    backgroundImage: gradient45deg,
    // maskImage: 'url(/background-pattern.png)',
    // backgroundClip: 'text',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
    // WebkitTextStroke: '1px white',
    // color: 'transparent',

    // textShadow: textStroke(1, 'white'),

    '&, & *': {
      position: 'relative',

      '&::after': {
        // background: 'none',
        content: 'attr(data-text)',
        zIndex: -1,
        left: 0,
        right: 0,
        position: 'absolute',
        // text-shadow: 6px 6px 9px rgba(0, 0, 0, 0.33);
        top: 0,
        textShadow: textStroke(1, 'white'),
      },
    },
  },

  // '*': {
  //   // backgroundImage: 'linear-gradient(45deg, rgba(111,49,170,1) 0%, rgba(252,70,70,1) 50%, rgba(252,227,69,1) 100%)',
  //   // backgroundClip: 'text',
  //   // WebkitBackgroundClip: 'text',
  //   // WebkitTextFillColor: 'transparent',
  //   color: 'white',
  //   position: 'relative',
  //   marginBottom: 0,
  //   // WebkitTextStroke: '1px black',
  //   // marginRight: rhythm2,
  //   // textShadow: textStroke(2, 'white'),
  //   '&::after': {
  //     background: 'none',
  //     content: 'attr(data-text)',
  //     zIndex: -1,
  //     left: 0,
  //     right: 0,
  //     position: 'absolute',
  //     // text-shadow: 6px 6px 9px rgba(0, 0, 0, 0.33);
  //     top: 0,
  //     textShadow: textStroke(1, 'black'),
  //     textAlign: 'center',
  //   },
  // },

  [`
    & span,
    & em`
  ]: {
    // display: 'inline-block',
    color: 'inherit',
    fontWeight: 'inherit',
  },

  '& a': {
    fontWeight: 'inherit',
    // display: 'inline-block',
    color: 'inherit',
    textDecoration: 'underline',

    // '&::after': {
    //   textDecoration: 'underline',
    //   // textShadow: textStroke(1, 'black'),
    // },
  },

  // [`
  //   & a:hover,
  //   & a:focus,
  //   & a:active`
  // ]: {
  //   // textShadow: 'none',
  //   WebkitTextFillColor: 'white',
  // },

  [`
    & h1,
    & h2,
    & h3,
    & h4,
    & h5,
    & h6,
    & p
  `]: {
    marginBottom: 0,
    // marginRight: rhythm2,
    // textShadow: textStroke(2, 'white'),
  },

  // '& h1': {
  //   ...font300,
  //   lineHeight: 1.1,

  //   color: '#fa4647',

  //   backgroundImage: gradient45deg,
  //   // maskImage: 'url(/background-pattern.png)',
  //   // backgroundClip: 'text',
  //   WebkitBackgroundClip: 'text',
  //   WebkitTextFillColor: 'transparent',
  //   // color: 'transparent',

  //   '&::after': {
  //     textShadow: textStroke(1, 'white'),
  //   },
  // },

  '& p': {
    ...font100,

    '@media (min-width: 355px)': {
      ...font150,
    },

    lineHeight: 1.1,
  },

  [MIN_MOBILE_MEDIA_QUERY]: {
    '& h1': {
      ...font300,
    },

    '& p': {
      ...font150,
    },
  },

  [MIN_TABLET_MEDIA_QUERY]: {
    color: 'inherit',
    textShadow: textStroke(1, 'white'),

    '& h1': {
      backgroundImage: gradient45deg,
      ...font400,

      // '&::after': {
      //   // textShadow: textStroke(1, 'black'),
      // },
    },

    '& p': {
      ...font200,
    },
  },

  [MIN_DEFAULT_MEDIA_QUERY]: {
    '& h1': {
      ...font500,
    },

    '& p': {
      ...font200,
    },
  },
});

const scrollArrowCss = css({
  ...font50,

  position: 'absolute',
  bottom: rhythm4,
  left: 0,
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  color: '#fff',
  opacity: 0,

  transition: `opacity ${animationDuration}ms ease-out`,

  '@media (orientation: landscape)': {
    color: colors.body.text,
  },
});

const visibleScrollArrowCss = css({
  opacity: 1,
});

type Props = {
  scrollArrow?: boolean,
  children: React.ReactNode;
};

export const HomeHero = (props: Props) => {
  const {children, scrollArrow} = props;

  const [showArrow, setShowArrow] = useState(false);

  useEffect(
    () => {
      if (!scrollArrow) {
        return;
      }

      const handler = () => {
        setShowArrow(window.scrollY < 100);
      };

      handler();
      window.addEventListener('scroll', handler);

      return () => { window.removeEventListener('scroll', handler); };
    },
    [scrollArrow],
  );

  const mappedChildren = useMemo(
    () => {
      const mapH1Children = (children: React.ReactNode): React.ReactNode => Children.map(children, (child) => {
        // console.log(child, isValidElement(child) && child.props.children === 'string')

        if (isValidElement(child)) {
          if (typeof child.props.children === 'string') {
            return [
              ' ',
              cloneElement(child, {
                'data-text': child.props.children,
              }),
              ' ',
            ];
          }

          if (Array.isArray(child.props.children)) {
            return cloneElement(child, {
              children: mapH1Children(child.props.children.map((child: React.ReactNode) => {
                if (typeof child === 'string') {
                  return (
                    <span>{child}</span>
                  );
                }

                return child;
              })),
            });
          }
        }

        return child;
      });

      const mapH1 = (children: React.ReactNode): React.ReactNode => Children.map(children, (child) => {
        if (isValidElement(child)) {
          if (child.type === 'h1' || child.props.mdxType === 'h1') {
            return mapH1Children(child);
          }

          return cloneElement(child, {
            children: mapH1(child.props.children),
          });
        }

        return child;
      });

      return mapH1(children);
    },
    [children],
  );

  return (
    <div css={homeHeroCss}>
      <Global styles={globalStyles} />

      <div css={childrenCss}>
        <Container css={childrenContainerCss}>
          <div css={childrenWrapperCss}>
            {mappedChildren}
          </div>
        </Container>
      </div>

      {scrollArrow && (
        <div css={[scrollArrowCss, showArrow && visibleScrollArrowCss]}>
          <FontAwesomeIcon icon={faAngleDoubleDown} fixedWidth />
        </div>
      )}
    </div>
  );
};

HomeHero.whyDidYouRender = true;
