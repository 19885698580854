import {MdxBody} from '../../components/MdxBody';
import {IndexLayout} from '../IndexLayout';
import {CmsPageBaseLayout} from './CmsPageBaseLayout';

export const CmsPageIndexLayout = (props: any) => {
  const {children, ...cmsPageBaseLayoutProps} = props;

  return (
    <CmsPageBaseLayout {...cmsPageBaseLayoutProps}>
      <IndexLayout>
        <MdxBody>
          {children}
        </MdxBody>
      </IndexLayout>
    </CmsPageBaseLayout>
  );
};
