// https://stackoverflow.com/a/8712442
export const textStroke = (width: number, color: string) => {
  const stroke = [];

  for (let i = -width; i <= width; i += 1) {
    for (let j = -width; j <= width; j += 1) {
      stroke.push(`${i * 1}px ${j * 1}px 0 ${color}`);
    }
  }

  return stroke.join(', ');
};
