import {css} from '@emotion/core';
import {Container} from '../components/Container';
import {Footer} from '../components/Footer';
import {Header} from '../components/Header';
import {MainContent} from '../components/MainContent';
import {rhythm2} from '../styles/typography';
import {BaseLayout} from './BaseLayout';

const mainCss = css({
  // background: 'white',

  minHeight: [`calc(100vh - ${rhythm2})`, `calc(var(--window-height, 100vh) - ${rhythm2})`],

  paddingBottom: rhythm2,

  '@media print': {
    padding: 0,
  },
});

type Props = {
  children: React.ReactNode,
};

export const IndexLayout = (props: Props) => {
  const {children} = props;

  return (
    <BaseLayout>
      <Header noLogoLink />

      <div css={mainCss}>
        <MainContent>
          <Container>
            <main className="layout-wrapper">
              {children}
            </main>
          </Container>
        </MainContent>
      </div>

      <Footer />
    </BaseLayout>
  );
};
